<template>
  <page-setup>
    <v-container fluid>
      <v-row dense>
        <v-col class="d-flex align-center">
          <div class="text-h5 text-color-main font-weight-black my-4">
            {{ title }}
          </div>
          <v-spacer></v-spacer>
          <div>
            <create-event-dialog></create-event-dialog>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <event-calendar-component />
        </v-col>
      </v-row>
    </v-container>
  </page-setup>
</template>

<script>
const EventCalendarComponent = () =>
  import("../components/EventCalendarComponent.vue");

const CreateEventDialog = () => import("../dialogs/CreateEventDialog.vue");

export default {
  components: {
    EventCalendarComponent,
    CreateEventDialog
  },
  data: () => ({
    title: "Events"
  })
};
</script>
